import type { HTMLAttributes } from 'react';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import type { Shared } from 'utils/types';
import styles from './Icons.module.scss';
import { FontAwesomeIconProps, FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { withStyles } from 'isomorphic-style-loader';

export const createIcon = (themeIconKey: string, defaultIcon: IconDefinition) => {
  type Props = {
    alt?: string | null;
    title?: string | null;
  } & Omit<Shared<HTMLAttributes<HTMLElement>, FontAwesomeIconProps>, 'title'>;

  const Icon = ({ alt = '', className = '', title, ...props }: Props) => {
    const src = useSelector(({ theme }) => theme.values && theme.values[themeIconKey]);

    if (src)
      return <img src={src} alt={alt} title={title} className={`${styles.icon} ${className}`} {...props} />;

    return (
      <FontAwesomeIcon
        icon={defaultIcon}
        title={title as string | undefined}
        className={`${styles.defaultIcon} ${className}`}
        {...props}
      />
    );
  };

  return withStyles(styles as any)(Icon);
};

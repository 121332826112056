import type { HTMLAttributes, FC } from 'react';
import type { Shared } from 'utils/types';
import styles from './Icons.module.scss';
import { useSelector } from 'react-redux';
import { withStyles } from 'isomorphic-style-loader';

export function createIcon(themeIconKey: string, DefaultComponent: FC<HTMLAttributes<SVGSVGElement>>) {
  type Props = {
    alt?: string | null;
    title?: string | null;
  } & Omit<Shared<HTMLAttributes<HTMLElement>, HTMLAttributes<SVGSVGElement>>, 'title'>;

  const Icon = ({ alt = '', className = '', title, ...props }: Props) => {
    const src = useSelector(({ theme }) => theme.values && theme.values[themeIconKey]);

    if (!src)
      return <DefaultComponent title={title as string | undefined} className={`${styles.defaultIcon} ${className}`} {...props} />;

    return <img src={src} alt={alt} title={title} className={`${styles.icon} ${className}`} {...props} />;
  };

  return withStyles(styles as any)(Icon);
}

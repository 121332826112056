exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Icons_icon{display:inline-block;font-size:4em;max-height:100%;max-width:100%;overflow:hidden;text-indent:100%;white-space:nowrap}svg.Icons_default-icon{font-size:4em;vertical-align:-.031em}", ""]);

// exports
exports.locals = {
	"icon": "Icons_icon",
	"default-icon": "Icons_default-icon",
	"defaultIcon": "Icons_default-icon"
};
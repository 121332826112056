/* eslint-disable react/no-multi-comp */
import { HTMLAttributes } from 'react';

export const MovieIcon = ({ title, ...props }: HTMLAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" {...props}>
    {title && <title>{title}</title>}
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M4 6.47L5.76 10H20v8H4V6.47M22 4h-4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4z" />
  </svg>
);
